import React, { useState, useEffect } from "react";
import "./cloudnumberservice.css";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import cnsimg from "../../assets/images/cnsimg.jpg";
import internet from "../../assets/images/internet.png";
import handShake from "../../assets/images/handShake.png";
import setting from "../../assets/images/setting.png";
import chooseimg from "../../assets/images/chooseimg.png";
import cubic_img from "../../assets/images/cubic_img.png";
import banner1 from "../../assets/images/banner1.png";
import banner2 from "../../assets/images/banner2.png";
import operation_1 from "../../assets/images/operation-1.png";
import operation_2 from "../../assets/images/operation-2.png";
export default function CloudNumberService(props) {
  return (
      <div className="cloud-numbering">
        <Navbar
          heading="Cloud Numbering Service Offer"
          text="Elevate your telecommunications experience with Yuave’s Cloud Number Service (CNS), the ideal solution for hyper scalers, Contact Center technology providers, multinationals seeking local telephony numbering integration into Microsoft Teams or Zoom across their operating countries, as well as other UCaaS or CCaaS platforms, and Communication Platform as a Service (CPaaS) providers."
          img={cnsimg}
          extra_heading="CLOUD NUMBERING SERVICE"
          first_section = {true}
          setIsFormOpen = {props.setIsFormOpen}
          isFormOpen = {props.isFormOpen}
        ></Navbar>

      <div id="num-section-1" class="w-100 position-relative block-centered_title pb-4">
        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Key Features
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="num-section-2" class="w-100 position-relative block-4_main_topics_icon_title_and_summary pb-5">
        <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
          <div class="container">
            <div class="row rseh-md justify-content-center fix-style-any">
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%",minHeight:'fit-content' }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={internet}
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">Global <br /> Reach </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Yuave offers CNS in over 100 countries worldwide, including regions where coverage has traditionally been limited, such as Asia, Africa, Middle East and Latin America. Expand your reach and unlock new opportunities with our extensive global coverage.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={setting}
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Seamless Integration
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Integrate CNS effortlessly into your existing communication platforms, including Microsoft Teams, Zoom, and other UCaaS or CCaaS solutions. Enjoy seamless connectivity and enhanced telephony capabilities across your organization.
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 item-box item-box-any p-0 p-md-2">
                <div
                  class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                  style={{ height: "100%" }}
                >
                  <div class="d-block w-100 mb-3">
                    <img
                      src={handShake}
                    />
                  </div>
                  <div class="d-block w-100 mb-3">
                    <h4 class="style-h4">
                      Compliance and Reliability
                    </h4>
                  </div>
                  <div class="d-block w-100">
                    <div class="p-small">
                      Stay compliant with local regulations and industry standards while ensuring reliable and secure telecommunications operations. Yuave prioritizes compliance and reliability to provide peace of mind for your business.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 position-relative block-centered_title pt-2 pb-2 operations">
        <div class="w-100 section-centered_title pt-5 pb-2 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Operational Excellence
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="operations w-100 position-relative block-image_side_and_text_side_repeater ">
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={operation_1}
                  alt="Frame 46359"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">
                    Tailored Solutions
                  </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Customize your CNS setup to meet your specific requirements, whether you need Toll-free or Toll-access numbers, fixed or mobile access, or specialized routing options. Our flexible solutions adapt to your business needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  pe-lg-5">
                <img
                  src={operation_2}
                  alt="Frame 46361"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  ps-lg-5">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">
                    Dedicated Support
                  </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Benefit from dedicated support and assistance from our experienced team. We’re here to help you every step of the way, ensuring a smooth implementation and ongoing support for your CNS deployment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="w-100 position-relative block-centered_title pt-2 pb-2">
        <div class="w-100 section-centered_title pt-5 pb-2 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Why Choose Yuave?
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 position-relative block-image_side_and_text_side_repeater ">
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-4 text-center text-md-start  ps-lg-5">
                <img
                  src={chooseimg}
                  alt="Frame 46359"
                  class="mx-auto mb-4 my-md-2"
                  style={{

                    height: "300px"

                }}
                />
              </div>
              <div class="col-12 col-md-8 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <ul>
                  <br></br>

                  <li className="text-mute">
                      <b> Global Coverage:</b>
                      With CNS available in over 100 countries, including
                      regions with limited coverage, Yuave offers unparalleled
                      global reach for your telecommunications needs.
                  </li>
                  <br></br>
                  <br></br>
                  <li className="text-mute">
                      <b>
                          Innovation and Adaptability: </b>Stay ahead of the curve with
                      Yuave’s innovative solutions and adaptable platform. Whether
                      you’re a multinational corporation or a growing startup,
                      our CNS offerings scale to meet your evolving needs.
                  </li>
                  <br></br>
                  <br></br>

                  <li className="text-mute">
                      <b>
                          Exceptional Value:
                      </b>
                      Experience exceptional value with Yuave’s CNS,
                      combining global coverage, seamless integration,
                      and reliable support at competitive pricing.
                  </li>
                  <br></br>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="operations w-100 position-relative block-image_side_and_text_side_repeater ">
        <div class="w-100 section-row_repeater">
          <div class="container">
            <div class="row align-items-center pt-3 pt-md-5 pb-5 ">
              <div class="col-12 col-md-6 text-center text-md-start  ps-lg-5">
                <img
                  src={cubic_img}
                  alt="Frame 46359"
                  class="mx-auto mb-4 my-md-2"
                />
              </div>
              <div class="col-12 col-md-6 mt-3 mt-md-0  pe-lg-5 order-md-first">
                <div class="w-100 d-block mb-3">
                  <h3 class="style-h3">
                    Get Started Today
                  </h3>
                </div>
                <div class="w-100 d-block">
                  <div class="p-small">
                    <p>
                      Unlock the power of global telecommunications with Yuave’s Cloud Number Service. Contact us now to learn more and discover how CNS can transform your communication strategy across borders.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      <div class="call-expert-section w-100 position-relative block-cta_strip_2_images ">
        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={banner1}
                  alt="Ready to drive transformational change for your business and customers?"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={banner2}
                  alt="Ready to drive transformational change for your business and customers?"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class="style-h2">
                  Want to discuss Cloud Numbering Service?
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    
                    class="a-btn blact_btn mb-5 mb-md-0 mx-auto"
                    onClick={()=>{props.setIsFormOpen(true);}}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}