import "./fintech.css";
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/FINTECH-kasookoo-for-fintech-image.png";

import safeicone from "../../assets/images/trust.svg"
import growbusinessicon from "../../assets/images/grow-business-icon.svg"
import cloudtrcgicon from "../../assets/images/technalogy_experties_icon.svg"

// import shieldIcon from "../../assets/images/shield.svg"
import headphonesicon from "../../assets/images/headphones1.svg"
import bespokeicone from "../../assets/images/booster-icon.svg"
// import PDPAicon from "../../assets/images/Group 1174.svg"

import senticon from "../../assets/images/send.svg"
import maskingicon from "../../assets/images/shield.svg"
import micicon from "../../assets/images/voice-recorder (1).svg"
import messageicon from "../../assets/images/premium-q-calls-icon.svg"

import realiablecallsimg from "../../assets/images/FINTECH-make-reliable-image.png"
import productivityImg from "../../assets/images/FINTECH-productivity-image.png"
import simplesecureimg from "../../assets/images/FINTECH-simple-and-secure-image.png"
import qualitycustomerimg from "../../assets/images/FINTECH-quality-customer-image.png"
import woamnonsmartphone from "../../assets/images/FINTECH-muslim-woman-with-smartphone.png"
import discoverimg from "../../assets/images/FINTECH-discover-image.png"

import bottomLeftImg from "../../assets/images/7-2.png"
import bottomRightImg from "../../assets/images/7-1.png"

import speaker_white_icon from '../../assets/images/speaker_white_icon.svg'
import user_verification_icon from '../../assets/images/user_verification_icon.svg'
import headphone_white_icon from '../../assets/images/headphones_white_icon.svg'

export default function FinTech(props) {
  return (
    <div className="fin-tech">
      <Navbar
        heading="Create an incomparable digital journey for your clients"
        text="Financial applications and software that handle money must be trustworthy and easy to use.
Ensure that your app or platform fulfills this expectation by incorporating voice and messaging channels that facilitate strong and effective communication between your company and its customers.
"
        img={optimize_customer_img}
        extra_heading="YUAVE FOR FINTECH"
        first_section = {true}
        setIsFormOpen = {props.setIsFormOpen}
        isFormOpen = {props.isFormOpen}
      ></Navbar>
      <div
        id="num-section-1"
        class="w-100 position-relative block-centered_title  pb-4"
      >
        <div class="w-100 section-centered_title  pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Improve your customer experience and let your results speak
                  for themselves
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 section-4_main_topics_icon_title_and_summary py-3">
        <div class="container">
          <div class="row rseh-md justify-content-center fix-style-any">
            <div class="col-12 item-box item-box-any  p-md-2">
              <div
                class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                style={{ height: "409px" }}
              >
                <div class="d-block w-100 mb-3">
                  <img
                    src={safeicone}
                    alt="Group 1000005055"
                  />
                </div>
                <div class="d-block w-100 mb-3">
                  <h4 class="style-h4">Safe and protected interactions</h4>
                </div>
                <div class="d-block w-100">
                  <div class="p-small">
                    Foster loyalty and trust by empowering your agents to
                    provide personalized touchpoints through voice and messaging
                    channels using our comprehensive suite of customer
                    engagement tools and APIs.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 item-box item-box-any  p-md-2">
              <div
                class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                style={{ height: "409px" }}
              >
                <div class="d-block w-100 mb-3">
                  <img
                    src={growbusinessicon}
                    alt="Group 1000005250"
                  />
                </div>
                <div class="d-block w-100 mb-3">
                  <h4 class="style-h4">
                    Grow your business quickly and easily
                  </h4>
                </div>
                <div class="d-block w-100">
                  <div class="p-small">
                    When considering entry into new markets or expanding your
                    operations, streamline your communications by utilizing a
                    single provider, minimizing costs, and eliminating the
                    complexities of connectivity.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 item-box item-box-any p-md-2">
              <div
                class="w-100 pb-4 ps-3 pe-5 p-md-4 item-4_topics rs_equalHeight"
                style={{ height: "409px" }}
              >
                <div class="d-block w-100 mb-3">
                  <img
                    src={cloudtrcgicon}
                    alt="Group 1000005251"
                  />
                </div>
                <div class="d-block w-100 mb-3">
                  <h4 class="style-h4">Dynamic cloud-based communications</h4>
                </div>
                <div class="d-block w-100">
                  <div class="p-small">
                    Eliminate concerns about intricate software or hardware
                    installations. Keep your data securely stored online and
                    seamlessly integrate cloud communications with your existing
                    suite of business tools.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-3"
        class="w-100 position-relative block-centered_title pt-5"
      >
        <div class="w-100 section-centered_title pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Engage with clients on their preferred terms of communication
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#f1f5fe" }}
        class="w-100 section-centered_title  py-3 align-items-center text-left text-md-center"
      >
        <div class="container">
          <div class="row ">
            <div class="col-12 mx-auto col-md-8">
              <p class="p-small">
                Finances are a delicate matter. If clients feel they are not
                receiving top-notch service, they may turn to your competitors.
                Stay proactive in understanding their communication expectations
                to ensure their satisfaction remains high.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-5"
        class="w-100 position-relative block-4_columns_with_counters "
      >
        <style></style>
        <div class="container section-4_columns_with_counters strip-counter">
          <div class="row strip-counter-style-centered ">
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">33</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers, surpassing all other options, would like to choose
                SMS as a communication channel.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">54</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers would be interested in voice calls if they want to
                instantly resolve issues.
              </div>
            </div>
            <div class="col-12 col-lg py-4 py-lg-2 ">
              <div class="d-block w-100 style-h1">
                <div class="strip-counter-header d-block mb-2 text-left text-md-center">
                  <span class="count">45</span>
                  <span class="count-text-after">%</span>
                </div>
              </div>
              <div class="d-block w-100 w-lg-75 mx-auto p-small text-left text-md-center">
                of consumers would like to be updated over the emails for policy
                updates.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-6"
        class="w-100 position-relative block-blog_cta pb-5"
      >
        <div class="w-100 section-blog_cta pt-3 pb-md-3">
          <div class="container">
            <div class="w-100 cta-row my-4">
              <div
                class="cta-we-can-help px-4 px-md-5 py-3 text-white mx-auto"
                style={{
                  backgroundImage:
                    "url('https://toku.co/wp-content/uploads/2023/02/Frame.png')",
                }}
              >
                <div class="d-block w-100">
                  <h4 class="style-h4 mb-3">
                    Want to learn more about how consumers want to engage with
                    companies like yours?
                  </h4>
                  <div class="description mb-3">
                    *Read Toku's Singapore Consumer Engagement Report 2022
                  </div>
                </div>
                <div class="d-block w-100">
                  <a class="btn btn-yellow" target="" href="">
                    Download Here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-7"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-purple">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={speaker_white_icon}
                      alt="KASOOKOO IN-APP VOICE"
                    />
                 IN-APP VOICE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Make reliable calls with your clients
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      By adding calls to your app, you can ensure that the
                      caller and user are legitimate. Prevent scams and keep
                      your customers secure by adding a redundant communication
                      channel they know they can trust. This will also make
                      calls to your team more reliableBy providing an additional
                      communication channel that instills trust, you can ensure
                      a secure environment. Moreover, this integration will
                      significantly boost the reliability of calls made to your
                      team.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/#/in-app-voice-calling" class="btn btn-arrow text-start" target="">
                    Learn How to Add Voice Calls to Your Application
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={realiablecallsimg}
                  alt="Image 84"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-8"
        class="w-100 position-relative block-topic_center_repeater pt-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={user_verification_icon}
                      alt="USER VERIFICATION"
                    />
                    USER VERIFICATION
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Simple and secure verification process
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Elevate the security of your banking, finance, or
                      investment experiences by enabling voice and messaging
                      verification capabilities. This powerful feature helps
                      combat fraud and creates a robust environment for your
                      users.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/#/user-varification" class="btn btn-arrow text-start" target="">
                    Create Robust Authentication
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src={simplesecureimg}
                  alt="Image 88"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-none d-sm-block bg-archer bg-archer-down">
        <svg
          width="2500"
          height="178"
          viewBox="0 0 2500 178"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 178C0 178 506.625 54 1253.5 54C2000.38 54 2500 178 2500 178V0H0L0 178Z"
            fill="#f1f5fe"
          ></path>
        </svg>
      </div>
      <div
        id="num-section-9"
        class="w-100 position-relative block-topic_center_repeater pb-5"
      >
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-blue">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src={headphone_white_icon}
                      alt="KASOOKOO CONTACT CENTRE"
                    />
                     CONTACT CENTRE
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Quality customer service to Fintech users
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Provide a seamless and secure customer service experience
                      across multiple channels, guaranteeing utmost convenience
                      and data protection for users who are updating their
                      banking details, discussing their account information, or
                      lodging complaints.
                    </p>
                  </div>
                </div>

                <div class="w-100 d-block mb-3 mb-md-0">
                  <a href="/#/contact-center" class="btn btn-arrow text-start" target="">
                    Begin Your Omnichannel Journey
                  </a>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  ps-md-5">
                <img
                  src={qualitycustomerimg}
                  alt="Image 89"
                  class="w-100 h-auto mx-auto my-2 img-class"
                  
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="num-section-10"
        class="w-100 position-relative block-topic_center_repeater py-5"
      >
        <style></style>
        <div class="w-100 section-topic_center_repeater">
          <div class="container">
            <div class="row align-items-center style-green">
              <div class="col-12 col-md-6 col-lg-4 side-one">
                <div class="w-100 d-block">
                  <div class="top-title ms-0 me-auto">
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/Group-1000005009.svg"
                      alt="TOKU BUSINESS TELEPHONY"
                    />
                    KASOOKOO BUSINESS TELEPHONY
                  </div>
                </div>
                <div class="w-100 d-block">
                  <div class="title-section-h2">
                    Productivity and Profitability with Unified Communications
                  </div>
                </div>

                <div class="w-100 d-block">
                  <div class="entry p-small">
                    <p>
                      Productivity and Profitability with Unified Communications
                      Achieve cost savings on internal communications, enhance
                      operational efficiency, and ensure seamless collaboration
                      for your team by leveraging the high-quality calling
                      capabilities of
                      <a href="/kasookoo-for-microsoft">Microsoft Teams</a> or
                      <a href="/kasookoo-for-zoom">Zoom Phone</a>.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-8 side-two  pe-md-5 order-md-first">
                <img
                  src="https://toku.co/wp-content/uploads/2023/01/Image-90.png"
                  alt="Image 90"
                  class="w-100 h-auto mx-auto my-2 img-class"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="num-section-11"
        class="w-100 position-relative block-centered_title pb-3 pt-5"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-3 pt-5 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Discover the advantages of partnering with Yuave for
                  financial service providers
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-12"
        class="w-100 position-relative block-image_on_the_left_and_4_subjects_on_the_right pt-3 pb-5"
      >
        <style></style>
        <div class="w-100 section-image_on_the_left_and_4_subjects_on_the_right py-3">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-4">
                <img
                  src={discoverimg}
                  alt="Image 91"
                  class="w-100 h-auto mb-4"
                />
              </div>
              <div class="col-12 col-md-8 ps-md-5">
                <div class="row">
                  {/* <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={shieldIcon}
                          alt="Safe. Secure. Reliable"
                          title="Safe. Secure. Reliable"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Security and Reliability </h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          As a Tier 1 Aggregator recognized by IMDA (Infocomm
                          Media Development Authority), we adhere to all IMDA
                          requirements, ensuring compliance and implementing
                          robust anti-spoofing protocols for protected SMS.
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={bespokeicone}
                          alt="Customised Service"
                          title="Customised Service"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Bespoke Offerings</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          Explore our diverse selection of cutting-edge cloud
                          communication solutions, meticulously designed to
                          cater to the unique requirements of your project.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={headphonesicon}
                          alt=" Expert Support"
                          title=" Expert Support"
                          style={{width:'60px'}}
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">Quality Customer Support</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          Benefit from the expertise and responsiveness of our
                          skilled technical teams, based in Middle-east and
                          Africa and Middle East, who are readily available to support and
                          swiftly resolve any issues you may encounter.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-12 col-md-6">
                    <div class="w-100 d-block block-style">
                      <div class="w-100 d-block mb-3">
                        <img
                          src={PDPAicon}
                          alt="Compliant with PDPA and DSS"
                          title="Compliant with PDPA and DSS"
                        />
                      </div>
                      <div class="w-100 d-block mb-3">
                        <h4 class="style-h4">PDPA and DSS Compliant</h4>
                      </div>
                      <div class="w-100 d-block mb-3">
                        <div class="p-small pb-3">
                          We understand the critical importance of compliance
                          with regulatory standards such as ISO 27001, PDPA
                          (Personal Data Protection Act), and DSS (Digital
                          Service Standards). As a result, we place utmost
                          importance on meeting and exceeding these
                          requirements.
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-14"
        class="w-100 position-relative block-centered_title pb-4"
      >
        <style></style>

        <div class="w-100 section-centered_title pb-4 py-3 align-items-center text-left text-md-center">
          <div class="container">
            <div class="row ">
              <div class="col-12 mx-auto col-md-8">
                <h2 class="style-h2">
                  Discover Our Range of Products and Solutions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-15"
        class="w-100 position-relative block-2_items_in_row_with_image_title_text_link pb-5"
      >
        <div class="container section-2_items_in_row_with_image_title_text_link style-slimmer">
          <div class="row rseh-md pb-5">
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/programmable-voice"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src={micicon}
                        alt="Programmable Voice  "
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Voice
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Embed voice calls in your product
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item ">
                <a
                  href="/#/programmable-messaging"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src={messageicon}
                        alt="Programmable Messaging"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Programmable Messaging
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Build the messaging experience you want
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item ">
                <a
                  href="/#/campaign-manager"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src={senticon}
                        alt="Campaign Manager"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Campaign Manager
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Compose, configure, and launch bulk messaging campaigns
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-0 mb-md-3">
              <div class="style-item">
                <a
                  href="/#/number-masking"
                  class="d-block w-100 position-relative rs_equalHeight pb-lg-3"
                  style={{ height: "159px" }}
                >
                  <span class="d-block d-lg-flex w-100">
                    <span class="d-block d-lg-flex w-100 w-lg-25 order-lg-last image">
                      <img
                        src={maskingicon}
                        alt="Number Masking"
                      />
                    </span>
                    <span class="d-block w-100 w-lg-75 pe-lg-3">
                      <span class="d-block w-100 w-md-60 style-h4 pb-3 title">
                        Number Masking
                      </span>
                      <span class="d-block w-100 p-small pb-2 text">
                        Anonymise personal numbers and protect your users
                      </span>
                    </span>
                  </span>
                  <span class="d-none d-md-block w-100 text-end">
                    <div class="el-arrow-link d-inline-block"></div>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="num-section-16"
        class="w-100 position-relative block-cta_strip_2_images "
      >
        <style></style>

        <div class="w-100 section-cta_strip text-white py-0 py-md-3">
          <div class="container pt-5 pt-md-0">
            <div class="row align-items-center py-4 py-md-5">
              <div class="col-12 col-md-3 d-none d-md-flex image-one ">
                <img
                  src={bottomLeftImg}
                  alt="Build a fintech experience you can bank on"
                  class=""
                />
              </div>

              <div class="col-12 col-md-3 image-two  order-md-last ">
                <img
                  src={bottomRightImg}
                  alt="Build a fintech experience you can bank on"
                  class="d-block mx-auto mx-md-0 mb-4 mb-md-0 w-75 w-md-auto"
                />
              </div>

              <div class="col-12 col-md-6 text-center">
                <div class="d-block w-100 px-md-5">
                  <h2 class=" style-h2">
                    Build a fintech experience you can bank on
                  </h2>
                </div>
                <div class="d-block w-100 px-md-5">
                  <div class="p-big"></div>
                </div>
                <div class="d-block w-100 px-md-5 mt-3 text-center">
                  <a
                    
                    class="a-btn blact_btn mb-5 mb-md-0 mx-auto a-btn"
                    onClick={()=>{props.setIsFormOpen(true)}}
                  >
                    Talk to an Expert
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
