import React from 'react'
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import optimize_customer_img from "../../assets/images/optimize-customer-img.png";
import './gdpr.css'
export default function GDPR() {
    return (
        <div className='gdpr'>
            <Navbar
                extra_heading="CONTACT CENTRE"
                heading="A highly efficient Contact Center for Increased Productivity"
                text="Enable smooth and effortless customer interactions by leveraging a comprehensive cloud-based contact center solution that seamlessly integrates calls, messages, and OTT conversations. Empower your agents and managers with robust integrations that enhance customer engagement to an unprecedented level."
                img={optimize_customer_img}
                
                first_section={false}
				
            ></Navbar>
            <section class="w-100 d-block hero-single-careers pt-5 d-flex align-items-center mb-5" style={{ backgroundColor: '#7DBAAB' }}>
                <div class="container py-5 text-white">
                    <div class="row text-center py-2">
                        <div class="col-12 col-md-8 col-xl-6 mx-auto">
                            <h1 class="style-h3">EU DATA PROTECTION</h1>
                            <h6>We work hard to protect your information</h6>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-center">

                    </div>
                </div>
            </section>


         

		<section id="contact">
				<div class="container">
					<h4 class="section-title wow fadeIn animated" data-wow-delay=".2s" style={{visibility: 'visible'}}><span>Summary</span></h4>
					<div class="row contact-div">
						<p class="left">We recognise that as a  Yuave user you entrust us with some of your personal information and it’s always been a priority for us to protect your data and to provide you with choices about controlling it.</p>
						<p class="left">Following the release of new EU GDPR regulations (May 2018) we have taken steps to provide complete transparency about how we use and protect your data. We’ve put this webpage together as a guide to answer some of the most common questions you might have.</p>
						<p class="left">You can find more specific detail about all of this in our <a href="/#/privacy-policy" style={{color: 'black'}}>Privacy Policy</a>, but we’ve also provided the high level information below:</p>
						<h4>Security and data centre location</h4>
						<p class="h2-before left"> Yuave’s primary data and servers are hosted at Amazon Web Services’ (AWS) data center (located in London and Dublin). We may add additional servers in other locations in the future and in the event that we choose to do this, we will appendix these locations to this policy.</p>
						<p class="">AWS provides several security capabilities and services to increase privacy and control network access:</p>
						<ul>
						<li>Network firewalls built into Amazon VPC, and web application firewall capabilities in AWS WAF allow us to control access to our applications.</li>
						<li>We use data encryption protocols built into the specific AWS services in use.</li>
						</ul>
						<p class="bullet-before left">We also use technical and physical controls designed to prevent unauthorised access to your personal data. We restrict access to personal data only to our employees who need to know this information in order to operate, develop or improve our service. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they do not meet these obligations.</p>
						<h4>Data retention</h4>
						<p class="h2-before left"> Yuave collects and retains various elements of your data during your relationship with us. In general we retain this data for a maximum period of 60 days if you choose to cancel your account.</p>
						<p class="left">We have provided specific detail about the data that we collect and retain, and where we store it. You can find this information underneath.</p>

					</div>
				</div>
		</section>


		<section id="pricing-section">
			<div class="container">
				<h4 class="section-title wow fadeIn animated" data-wow-delay=".2s" style={{visibility: 'visible'}}>EU General Data Protection Regulation (GDPR)</h4>
				<div class="row wow fadeInUp animated" data-wow-offset="10" data-wow-delay="0.5s" style={{visibility: 'visible'}}>
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-11 center-div">
								<h4>What is GDPR?</h4>
								<p class="h2-before left">The General Data Protection Regulation (GDPR) is a new privacy legislation that replaces the EU Data Protection Directive (Directive 95/46/EC) within the European Union. The GDPR regulates the collection, use, transfer, and sharing of personal data with the key purpose of protecting it.</p>
								<h4>Why is GDPR important?</h4>
								<p class="h2-before left">GDPR adds some new requirements regarding how companies should protect individuals' data that they process. It also raises the stakes for compliance by increasing enforcement and imposing greater fines for breaches. We are following the developments about GDPR and are taking the necessary steps to become compliant.</p>
								<h4>What constitutes personal data?</h4>
								<p class="h2-before left">Personal data includes any information related to a living resident or citizen of the EU that can be used to directly or indirectly identify the person. It can be anything from a name, a photo, an email address, medical information, or even an IP address or cookie.</p>
								<h4>Who does the GDPR affect?</h4>
								<p class="h2-before left">The GDPR affects companies processing the personal data of individuals residing in the European Union, regardless of a company’s location. It applies not only to organisations located within the EU, but also to organisations located outside of the EU if they offer goods or services to or monitor the behavior of EU residents and/or citizens.</p>
								<h4>What happens when the UK leaves the EU?</h4>
								<p class="h2-before left">The UK is hoping for a unique status under GDPR and are working towards it. For the time being the UK has declared it will be GDPR compliant and its new data protection bill is in line with GDPR.</p>
								<h4>How will the GDPR affect businesses?</h4>
								<p class="h2-before left">The GDPR requires organisations to be transparent on how personal data is collected, used, and stored. This requires transparency from organisations on what personal data is collected, purposes for which it is collected, and who it is shared with. It also requires companies to enable individuals whose personal data is being processed to exercise their rights in relation to their data. The GDPR also requires companies to ensure appropriate protections when EU personal data is transferred outside the EU (including transfers to the US).</p>
								<h4>What new user rights does GDPR regulate?</h4>
								<ul>
								<li>Right to Access. EU residents and citizens (or “Data Subjects,” as they are called in the regulation) have the right to obtain confirmation from the organisation that has collected their data as to whether their personal data is being processed, where, and for what purpose. They also currently have (and will continue to have under the GDPR) the right to receive a copy of this personal data.</li>
								<li>Right to Be Forgotten (or Data Erasure). Data Subjects can demand that the organisations erase their personal data and cease further dissemination of the data.</li>
								<li>Data Portability. Data Subjects can receive the personal data concerning them (which they have previously provided) in a machine-readable format and have the right to transmit that data to another organisation.</li>
								</ul>
								<h4>How do we process your information?</h4>
								<p class="left">Your IP Address &amp; Cookies (prospective customers &amp; customers)<br/>The first time that you visit our website we store a record of your IP address on file and use cookies to track any subsequent visits and personalise your experience. If you wish to obtain a transcript of any data we hold for you we can supply this information on request, providing that we are able to identify you by your IP address in our database. We will store your IP address for 24 months, following which the data is permanently deleted. If you wish for us to permanently delete your data prior to the end of this retention period, please just let us know.</p>
								<p class="left">Email Address (prospective customers)<br/>Prior to becoming a  Yuave customer, you may choose to provide us with your email address to book a demo, ask a question, or download content from our website or blog. We will store your email address, and any other information you provide to us and use that data to contact you about  Yuave. This data will be retained for 24 months, following which it is permanently deleted (unless you become a customer in that period). You can unsubscribe from our emails at any time.</p>
								<p class="left">Contact Name, Company Name, Email Address (customers)<br/>We collect this information from you when you either a) Engage with content that we provide via our website or blog, or b) sign up for a free trial of our software. If you cancel your  Yuave account we will store this information for 60 days, following which the data is permanently deleted. If you wish for us to permanently delete your data prior to the end of this retention period, please just let us know.</p>
								<p class="left">In-app activity, device model, operating system (customers)<br/>We collect this information whilst you are actively using any of our applications - MacOS, Windows, iOS or Android. We update and store this information in real-time whilst you are using  Yuave. If you cancel your  Yuave account we will store this information for 60 days, following which the data is permanently deleted. If you wish for us to permanently delete your data prior to the end of this retention period, please just let us know.</p>
								<p class="left">Payment card details (customers)<br/>Your payment card details are not stored on our own systems. They are collected either via our Desktop or Android app, or via our admin website. The information is passed directly to Stripe (our payment processor - see section below).</p>
								<p class="left">Calls, Call Activities &amp; Messages (customers)<br/>We store your call activities, voicemail activities, SMS activities (where relevant) and Call recordings in your  Yuave account, to allow accurate billing and enable your call analytics. We also collect and store call statistics for quality and platform stability analysis. These statistics are not deleted, but are anonymised in the event that you cancel your account.  Yuave staff are able to view activities on your account, but are not able to listen to call recordings, voicemails, or to read any transcriptions of activity, without your explicit permission (which will be sought and logged if such an activity review is necessary). We store these records for the life of your account, but you have the option to delete these at any time.</p>
								<p class="left">Your customers’ contact data (customers)<br/> Yuave allows you to synchronise and save your contact data in your account.  Yuave staff are able to view phone numbers, for example when reviewing call activity on your account for problem resolution purposes. This data will never be used to contact your customers and the data will never be shared outside of  Yuave. We store these call records for the life of your account, but you have the option to delete these at any time.</p>
								<p class="left">Data sharing (prospective customers &amp; customers)<br/>We do not and will not share any of your data with any third party other than the sub-processors detailed below. The only exception to this is if we receive a Police or UK Security Services request for information relating to a phone number on your  Yuave account, in which case we are legally-bound to provide this information to the requestor.</p>
								<p class="left">Software integrations (customers)<br/> Yuave allows integration with a number of third party software systems such as Hubspot, Capsule, Pipedrive and Zoho.  Yuave posts call activity and call recordings (on demand) into your CRM system. As explained above call data and call recordings are permanently deleted after 60 days if you close your  Yuave account, however  Yuave is not able to delete the call activity that has been posted into your CRM system, so you will need to delete that information yourself, if you wish to do so.</p>
								<h4>Who are our sub-processors?</h4>
								<p class="h2-before left">We share certain information with companies that may be considered our "sub-processors" under GDPR. This information is limited to the following:</p>
								<ul>
								<li>Stripe - for payments. We process your company name &amp; billing address. Your payment card details are passed directly to Stripe - they do not pass through our systems. All data is deleted when the  Yuave account is deleted. Please follow the <a href="https://stripe.com/gb/privacy" style={{color: 'black'}}>link</a> to see their privacy policies.</li>
								</ul>
								<p class="left">*Note - collection and retention information for sub-processors above was correct at the time of publishing (11/05/2018). For the most up to date information please use the links provided.</p>
								<h4>How do we manage access to your information?</h4>
								<p class="h2-before left">Our intention is to service access to information requests (such as delete and export) manually. If you have an account with us, you may access, correct, or request that we delete your personal data by contacting us at help@ Yuave.com.</p>
								<p class="h1-after left">This request can include personal data of other individuals, like your employees or customers that you have provided to us and who have requested this of you. We will respond to all requests within 14 days or less, which is well within the GDPR requirement of 60 days.</p>
								<h3>What has  Yuave done to comply with GDPR?</h3>
								<h4>We have implemented and are implementing changes</h4>
								<p class="h2-before left">Our information security team is working to prepare  Yuave for GDPR. We have already fully reviewed our data processing activities, and are making any changes that are needed.</p>
								<h4>We only process data that is necessary</h4>
								<p class="left">We only store and process data that is necessary to fulfill our contract with you. For any data that falls outside of this, we will seek and record your consent to do so.</p>

					</div>
				</div>
			</div>
		</section>

		<section id="contact">
			<div class="container">
				<h4 class="section-title wow fadeIn animated" data-wow-delay=".2s" style={{visibility: 'visible'}}>Data Processing Agreement</h4>
				<div class="row wow fadeInUp animated" data-wow-offset="10" data-wow-delay="0.5s" style={{visibility: 'visible'}}>
					<div class="col-lg-12 col-md-12 col-sm-12 col-xs-11 center-div">
						<p class="left">As  Yuave may be considered a sub-processor of your own customer data, we are offering a data processing addendum (DPA) for customers on request.</p>
						<p class="left">If you would like to receive a DPA, please email us at help@Yuave.com</p>
					</div>
				</div>
			</div>

</section>


	
            <Footer />

        </div>
    )
}
