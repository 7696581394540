import React from "react";
import "./resources.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import customerstoriesImg from "../../assets/images/customer-stories-1.png";

import tradeDepotLogo from "../../assets/images/userStory-tradedepot.png";
import DunfilLogo from "../../assets/images/userstory-dufil.png";
import wellahealth from "../../assets/images/userstory-wellahealth.png";
import richfield from "../../assets/images/userstory-richfield.png";

import bottomLeftImg from "../../assets/images/customer-stories-2.svg";

export default function Resources(props) {
  return (
    <div className="resource">
      <Navbar
        heading="Witness the transformative impact we have on customer experiences across diverse businesses"
        // text="At Toku Consulting, we understand that successful digital transformation requires much more than a one-size-fits-all approach. That’s why we adopt an end-to-end consultancy approach, guiding your enterprise on every step of the way from vision to conception and implementation of the right solution."
        img={customerstoriesImg}
        extra_heading="KASOOKOO CUSTOMER STORIES"
        first_section={true}
        setIsFormOpen={props.setIsFormOpen}
        isFormOpen={props.isFormOpen}
      ></Navbar>

      <div id="num-section-1" class="w-100 position-relative block-block_text ">
        <style></style>

        <div class="section-cs-main">
          <div class="" style={{ background: "#fff" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-0">
                  <div class="title mb-3">
                    <h3>Trade Depot</h3>
                  </div>
                  <div class="excerpt mb-3">
                    By implementing Kasookoo’s robust contact centre solution,
                    Trade Depot established a centralized communication platform
                    across Africa and Middle East to efficiently handle customer inquiries,
                    resolve issues, and provide prompt updates on order status
                    and delivery details. The solution enabled Trade Depot's 200
                    staff members, including remote sales teams, to access
                    relevant customer data, streamline their sales processes,
                    and enhance overall productivity.
                  </div>
                  <div class="link">
                    <a
                      // href="https://toku.co/customer-stories/how-gojek-uses-communication-apis/"
                      // title="How Gojek Applied Toku’s Communications APIs To Boost Customer Experience"
                      class="a-tn btn btn-arrow"
                      href="/#/tradespot-fullstory"

                    >
                      Read TRADE DEPOT's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                  // href="https://toku.co/customer-stories/how-gojek-uses-communication-apis/"
                  // title="How Gojek Applied Toku’s Communications APIs To Boost Customer Experience"
                  >
                    <img src={tradeDepotLogo} alt="tradedepot" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="" style={{ background: "#F1F5FE" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-1">
                  <div class="title mb-3">
                    <h3>Dufil Foods</h3>
                  </div>
                  <div class="excerpt mb-3">
                    The Kasookoo Contact Centre Solution offered Dufil Foods a
                    comprehensive and efficient way to manage and organize its
                    high-volume messaging campaign. By leveraging our advanced
                    automation capabilities, Kasookoo automatically sorted and
                    categorized incoming messages, ensuring that participant
                    details were organized in a systematic manner. This not only
                    saved valuable time but also eliminated the risk of
                    overlooking important messages or participant information.
                  </div>
                  <div class="link">
                    <a
                      // href="https://toku.co/customer-stories/how-lenskart-improved-their-sms-campaign-operational-efficiency-with-toku/"
                      // title="How Lenskart Improved their SMS Campaign Operational Efficiency with Toku"
                      href="/#/dufil-fullstory"
                      class="btn btn-arrow"
                    >
                      Read DUFIL's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                  // href="https://toku.co/customer-stories/how-lenskart-improved-their-sms-campaign-operational-efficiency-with-toku/"
                  // title="How Lenskart Improved their SMS Campaign Operational Efficiency with Toku"
                  >
                    <img src={DunfilLogo} alt="DUFIL Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="" style={{ background: "#fff" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-0">
                  <div class="title mb-3">
                    <h3>Wella Health</h3>
                  </div>
                  <div class="excerpt mb-3" style={{ textAlign: "justify" }}>
                    By leveraging Kasookoo Voice Solution, Wella Health was able
                    to streamline their patient outreach process and ensure
                    timely communication. The automated calling feature
                    eliminated the need for manual calls, saving time and
                    resources for the healthcare team. Patients received voice
                    reminders for their upcoming appointments, reducing the
                    likelihood of missed or forgotten visits.
                  </div>
                  <div class="link">
                    <a
                      // href="https://toku.co/customer-stories/cloud-phone-system-review-rma/"
                      // title="Cloud Phone System Review: How RMA Contracts Set Up A Full-Featured System In Less Than 20 Minutes"
                      href="/#/wellahealth-fullstory"
                      class="btn btn-arrow"
                    >
                      Read WELLA HEALTH's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                  // href="https://toku.co/customer-stories/cloud-phone-system-review-rma/"
                  // title="Cloud Phone System Review: How RMA Contracts Set Up A Full-Featured System In Less Than 20 Minutes"
                  >
                    <img src={wellahealth} alt="Wella Health Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="" style={{ background: "#F1F5FE" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-1">
                  <div class="title mb-3">
                    <h3>Richfield</h3>
                  </div>
                  <div class="excerpt mb-3">
                    Richfield, with over 130 users utilizing the integrated
                    sytem Kasookoo CRM and Leadsquared, experienced notable
                    improvements in operational efficiency and customer
                    experience. The integration enhanced telesales activities by
                    enabling effective call management and tracking, resulting
                    in a total call time of about 48,000 minutes per month. It
                    provided comprehensive insights and optimized sales
                    strategies. Additionally, the integration facilitated
                    efficient customer service management, handling a large
                    volume of calls, including over 55,000 answered calls per
                    month. The streamlined workflow improved query resolution,
                    leading to enhanced customer satisfaction and retention.
                  </div>
                  <div class="link">
                    <a
                      // href="https://toku.co/customer-stories/piing-powers-up-its-customer-service-with-cloud-pbx-toku/"
                      // title="Piing! Powers Up its Customer Service with Cloud PBX"
                      href="/#/richfield-fullstory"
                      class="btn btn-arrow"
                    >
                      Read RichField's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                  // href="https://toku.co/customer-stories/piing-powers-up-its-customer-service-with-cloud-pbx-toku/"
                  // title="Piing! Powers Up its Customer Service with Cloud PBX"
                  >
                    <img src={richfield} alt="richfiled Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="" style={{ background: " #fff" }}>
            <div class="container py-5">
              <div class="row p-0 py-md-5 py-3 py-md-0">
                <div class="col-12 col-md-6 my-auto mb-4 mb-md-auto order-0 order-md-0">
                  <div class="title mb-3">
                    <h3>
                      How BLACAZ. Transformed its Digital Operations for a
                      Seamless Customer Experience
                    </h3>
                  </div>
                  <div class="excerpt mb-3">
                    The rise of insurtech companies is giving traditional
                    companies a run for their money by empowering customers to
                    make informed purchases seamlessly online. As the first and
                    only insurance partner in Asia that offers customisable
                    insurance policies 100% online, BLACAZ. is all about
                    simplifying their customers’ journey and helping them
                    navigate through the complex industry. BLACAZ. manages
                    online […]
                  </div>
                  <div class="link">
                    <a
                      href="https://toku.co/customer-stories/blacaz-transformed-digital-operations-for-seamless-customer-experience/"
                      title="How BLACAZ. Transformed its Digital Operations for a Seamless Customer Experience"
                      class="btn btn-arrow"
                    >
                      Read BLACAZ's Story
                    </a>
                  </div>
                </div>
                <div class="col-12 col-md-6 my-auto text-center">
                  <a
                    href="https://toku.co/customer-stories/blacaz-transformed-digital-operations-for-seamless-customer-experience/"
                    title="How BLACAZ. Transformed its Digital Operations for a Seamless Customer Experience"
                  >
                    <img
                      src="https://toku.co/wp-content/uploads/2023/01/62f9ddd5b6e9ac1bde652279_Logo_Blacaz-1.png"
                      alt="BLACAZ Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div class="container section-block_text">
          <div class="row">
            <div class="col-12"></div>
          </div>
        </div>
      </div>
      <div
        id="num-section-2"
        class="w-100 position-relative block-cta_strip_2 "
      >
        <style></style>

        <div class="w-100 section-cta_strip_2 text-white text-center text-md-start ">
          <div class="container pt-5 pt-md-0">
            <div class="row py-md-5">
              <div
                class="col-12 col-md-6 d-flex ps-md-5  order-last order-md-last"
                style={{ verticalAlign: "bottom" }}
              >
                <img
                  src={bottomLeftImg}
                  alt="Ready to meet your customers where they’re at?"
                  class="w-100 img-class"
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div class="col-12 col-md-6 py-4 py-md-5 my-auto px-lg-4 ">
                <div class="d-block w-100">
                  <h2 class="style-h2">
                    Ready to meet your customers where they’re at?
                  </h2>
                </div>
                <div class="d-block w-100">
                  <div class="p-big">
                    <p>
                      Toku Solutions are designed to help make communications
                      smarter, and more seamless.
                    </p>
                  </div>
                </div>
                <div class="d-block w-100 mt-4">
                  <a
                    class="a-btn btn btn-yellow border-radius-50 mx-auto ms-0"
                    onClick={() => {
                      props.setIsFormOpen(true);
                    }}
                  >
                    Connect With Us{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
